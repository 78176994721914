<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'app',
  components: {
  }
}
</script>


<style lang="scss">
@import './assets/css/color.css';

#app {
  
font-family: STHeitiSC-Medium, STHeitiSC;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

body {
  margin: 0px;
  padding: 0px;
}
p {
  margin: 0px;
  padding: 0px;
}
// #lw_footer_alt{
//     display: none !important;
//   }

.WebMain {
   width: 1250px;
  //width: calc(100%);
  margin: 0 auto;
  
   .el-tabs__item {
    font-size: 20px;
    // color: #202020;

    .is-active {
      color: var(--second-color);
    }
  }
 
  .titleContent {
    margin-top: 50px;
    margin-bottom: 20px;
    .title {
      // border-left: 7px solid var(--second-color);
      font-size: 30px;
      color: #202020;
      line-height: 42px;
      margin-bottom: 5px;

      span {
        display: inline-block;
        width: 7px;
        height: 24px;
        background: #0177C4;
      }
    }

    .summary {
      font-size: 20px;
      color: #777777;
      line-height: 28px;
    }
  }
  .BtnContent {
    // width: 100%;
    // text-align: center;
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 50px;
    

    p {
      width: 125px;
      height: 42px;
      margin: 0 auto;
      border-radius: 4px;
      border: 1px solid var(--custom-color);
      color: var(--custom-color);
      line-height: 42px;
      text-align: center;
      cursor: pointer;

      span {

        font-weight: 600;
      }
    }
  }

  .DivContent {
    item {
      box-sizing: border-box;
    }
  }

  .p100 {
    font-size: 100px;
  }
  .p60 {
    font-size: 60px;
  }
  .p50 {
    font-size: 50px;
  }
  .p54 {
    font-size: 50px;
  }
  .p58{
    font-size: 58px;
  }
  .p40 {
    font-size: 40px;
  }
  .p30 {
    font-size: 30px;
  }
  .p32 {
    font-size: 32px;
  }
  .p28 {
    font-size: 28px;
  }
  .p26 {
    font-size: 26px;
  }

  .p25 {
    font-size: 25px;
  }

  .p17 {
    font-size: 17px;
  }

  .p34 {
    font-size: 34px;
  }

  .p20 {
    font-size: 20px;
  }
  .p21 {
    font-size: 21px;
  }
  .p18 {
    font-size: 18px;
  }
  .p16 {
    font-size: 16px;
  }
  .p15 {
    font-size: 15px;
  }
  .p14 {
    font-size: 14px;
  }
  .p13 {
    font-size: 13px;
  }
  .p22 {
    font-size: 22px;
  }

  .p24 {
    font-size: 24px;
  }

  .span17 {
    font-size: 17px;
  }

  .span24 {
    font-size: 24px;
  }
  .span20 {
    font-size: 20px;
  }
  .span18 {
    font-size: 18px;
  }
  .span16 {
    font-size: 16px;
  }
}

.newsContent {
  display: flex;
  flex-wrap: wrap;
  // justify-content: center;

  .item {
    width: 580px;
    // margin-right: 15px;
    margin-bottom: 15px;
    background: white;
    cursor: pointer;


    .img {
      width: 100%;
      height: 230px;
      overflow: hidden;
      position: relative;

      img {
        width: 100%;
        margin: 0;
        padding: 0;
        height: 100%;
      }

      .time {
        position: absolute;
        right: 40px;
        bottom: 10px;
        color: white;
        // z-index: 1;
      }
    }

    .summary {
      height: 110px;
      position: relative;
      box-sizing: border-box;
      padding: 0 35px;

      .title {
        // height: 100%;
        // box-sizing: border-box;
        padding-top: 20px;
        // padding: 20px 35px;
        font-size: 20px;
        font-weight: 600;
        line-height: 31px;
        display: -webkit-box;
        /** 对象作为伸缩盒子模型显示 **/
        overflow: hidden;
        word-break: break-all;
        /* break-all(允许在单词内换行。)  对pc端可以不要*/
        text-overflow: ellipsis;
        /* 超出部分省略号 */
        -webkit-box-orient: vertical;
        /** 设置或检索伸缩盒对象的子元素的排列方式 **/
        -webkit-line-clamp: 2;
        /** 显示的行数 **/

      }

      .leftStyle {
        position: absolute;
        left: 0;
        width: 7px;
        height: 0;
        background: var(--custom-color);
      }
    }

    &:hover {
      box-shadow: 8px 8px 12px 0px rgba(0, 0, 0, 0.18);
      transition: all .5s;

      .leftStyle {
        transition: all .5s;
        height: 100%;
      }
    }
  }
}



@media screen and (max-width:1179px) {
  .WebMain {
    max-width: 850px;
  }
  .newsContent{
    justify-content: center;
  }
}

@media screen and (max-width:900px) {
  .WebMain {
    width: 90%;
  }
}
@media screen and (min-width:1180px) {

.newsContent {
  .item {

    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(6) &:last-child {
      margin-left: 15px;
    }
  }
}
}
@media screen and (max-width:500px){
  .WebMain {

  .p50 {
    font-size: 38px;
  }
  .p60 {
    font-size: 36px;
  }
  .p40 {
    font-size: 24px;
  }
  .p58{
    font-size:38px;
  }
  .p32 {
    font-size: 24px;
  }
  .p28 {
    font-size: 20px;
  }
  .p26 {
    font-size: 18px;
  }
  .p25 {
    font-size: 19px;
  }
  .p15 {
    font-size: 12px;
  }
  .p18 {
    font-size: 16px;
  }
  .p17 {
    font-size: 15px;
  }

  .p34 {
    font-size: 26px;
  }

  .p20 {
    font-size: 16px;
  }
  .p21 {
    font-size: 15px;
  }
  .p16 {
    font-size: 14px;
  }

  .p24 {
    font-size: 16px;
  }

  .span17 {
    font-size: 14px;
  }

  .span24 {
    font-size: 18px;
  }
}
}
@media screen and (max-width:700px){
  .titleContent {
    .title {
      // border-left: 7px solid var(--second-color);
      font-size: 20px !important;
      line-height: 30px !important;
      span{
        margin-bottom: -5px;
      }
      
    }

    .summary {
      font-size: 16px !important;
    }
  }
}


.el-table,
.tableContent {
  min-height: 500px;
}

.el-table th.gutter {
  display: table-cell !important;
}

.el-table .el-table__body .el-table__cell {
  height: 32px !important;
  padding: 0px !important;
  line-height: 32px !important;
  font-size: 14px;
}

.el-table th.el-table__cell {
  height: 35px !important;
  padding: 0px !important;
  line-height: 35px !important;
  font-size: 14px;
}

// // 取消数字输入框后的上下减
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type='number'] {
  -moz-appearance: textfield !important;
}

// 设置按钮区域的样式
.searchBtn {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;

  // align-items: center;
  .el-button {
    margin-left: 20px !important;
    // margin-top: 20px;
    // padding-top: 20px;
  }

  .el-date-editor {
    margin-right: 20px;
  }

  .el-input,
  .el-select,
  .el-cascader,
  .el-date-picker {

    width: 150px !important;
    margin-right: 20px;
    // margin-bottom: 20px;
  }

  span {
    display: inline-block;
    margin-right: 10px;
    // line-height: 32px;
  }


}

.el-dialog__header {
  padding: 0px !important;
}

.block {
  text-align: center;
  // margin:0 auto;
  margin-top: 10px;

}</style>
