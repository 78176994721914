<template>
  <div class="header">
    <!-- 顶部 -->
    <!-- 电脑端顶部 -->
    <div class="web">
      <div class="WebMain">
        <div class="left">
          <img src="../../assets/img/header/logo.png" alt="" />
        </div>
        <div class="right">
          <ul>
            <li
              @click="checkPath(item.path)"
              :class="activeName === item.title ? 'active' : ''"
              v-for="item in menulist"
              :key="item.id"
            >
              <span class="span20">{{ item.title }}</span>
            </li>
            <li class="lang" @click="toDetail">
              <span class="p16">真实账户申请</span>
            </li>
          </ul>
        </div>
        <!-- <div class="lang">
          <p class="p16">真实账户申请</p>
        </div> -->
      </div>
    </div>
    <!-- 电脑小分辨率顶部 -->
    <div class="MinWeb">
      <div class="left">
        <img src="../../assets/img/header/logo.png" alt="" />
      </div>
      <!-- <div class="center">
        <span class="span18">{{ activeName }}</span>
      </div> -->
      <div class="right">
        <div class="language"></div>
        <i class="el-icon-s-fold" @click="openDrawer"></i>
      </div>
    </div>
    <el-drawer
      title="0"
      :visible.sync="drawer"
      :with-header="false"
      :append-to-body="true"
      size="70%"
      :modal-append-to-body="false"
    >
      <div class="drawerHead">
        <img src="../../assets/img/header/logo2.png" alt="" />
      </div>
      <ul class="drawerUl">
        <li
          :class="$route.path.indexOf(item.path) !== -1 ? 'active' : ''"
          v-for="item in menulist"
          :key="item.id"
          @click="checkPath(item.path)"
        >
          <span>{{ item.title }}</span>
        </li>
      </ul>
      <div class="langueChange">
        <div>
          <span>语言</span>
        </div>
        <div @click="checkPath('/home')">
          <img src="../../assets/img/header/Chinese.png" alt="" />
          <span>CHINESE</span>
        </div>
        <div @click="checkPath('/english/home')">
          <img src="../../assets/img/header/English.png" alt="" />
          <span>ENGLISH</span>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import { attachmentListAPI } from "@/api/attachment/attachment";
export default {
  data() {
    return {
      leftWidth: "5.5%", // 默认导航下面滑块的位置
      menulist: [
        {
          id: 1,
          title: "首页",
          path: "/home",
          text: { title: "", summary: "" },
        },
        {
          id: 2,
          title: "LMAX",
          path: "/detail/2",
          text: { title: "", summary: "" },
        },
        {
          id: 3,
          title: "交易产品",
          path: "/detail/3",
          text: { title: "", summary: "" },
        },
        
        {
          id: 5,
          title: "资讯中心",
          path: "/list/5",
          text: { title: "", summary: "" },
        },

        {
          id: 6,
          title: "客户中心",
          path: "/detail/6",
          text: { title: "", summary: "" },
        },
        {
          id: 4,
          title: "合作伙伴",
          path: "/partner",
          text: { title: "", summary: "" },
        },

        {
          id: 7,
          title: "关于我们",
          path: "/contact",
          text: { title: "", summary: "" },
        },
      ], // 菜单
      drawer: false, // 侧边栏模态框
      bannerList: [],
      imgPath: "",
    };
  },
  methods: {
    // 打开侧边栏
    openDrawer() {
      // console.log(1111)
      this.drawer = true;
    },
    // 切换路由
    checkPath(val) {
      this.$router.push(val);
      this.getList();
      this.drawer = false;
    },
    // 获取附件列表
    async getList() {
      if (this.$route.path !== "/index") {
        let info = { pageSize: 4, pageNum: 1, categoryId: 5 };
        const res = await attachmentListAPI(info);
       // console.log(res);
        if (res.code === 200) {
          this.bannerList = res.data.list;
          this.bannerList.forEach((item) => {
            if (item.title.indexOf(this.activeName) !== -1) {
              // console.log(name)
              // console.log(item.title)
              this.imgPath = item.path;
              this.$store.commit("changeBannerInfo", this.imgPath);
            }
          });
        }
      }
    },
    toDetail() {
      this.$router.push({
        path:'/detail/6',
        query: {
          id: 23
        }
      })
    }
  },
  created() {
    // this.getList();
  },
  computed: {
    activeName() {
      let name = "";
      let path = this.$route.path;
      
      this.menulist.forEach((item) => {
        if (item.path === path) {
          name = item.title;
          this.$store.commit("changeBannerText", item.text);
        }
      });
      // console.log(path)
      // console.log(name)
      return name;
    },
  },
};
</script>

<style lang="scss" scoped>
.drawerHead {
  height: 47px;
  width: 100%;
  padding-left: 15px;
  background: var(--custom-color);
  line-height: 47px;
  img {
    height: 17px;
  }
}
.drawerUl {
  list-style: none;
  margin: 0;
  padding: 0;
  // background: #222222;
  //   width: 100%;
  //   height: 126px;
  //   clip-path: polygon(0 0, 100% 0, 100% 50%, 0 100%);

  li {
    cursor: pointer;
    line-height: 45px;
    padding-left: 15px;
    border-bottom: 1px solid #eee;
    // opacity: 0.08;
    span {
      font-size: 16px;
    }
  }

  .active {
    // border-bottom: 2px dashed #eee;

    span {
      color: var(--custom-color);
    }
  }
}
.langueChange {
  padding-left: 20px;
  padding-top: 20px;
  div {
    height: 63px;
    width: 100%;
    // line-height: 63px;
    border-bottom: 1px solid #eee;
    display: flex;
    align-items: center;
    img {
      width: 54px;
      margin-right: 20px;
    }
  }
}

.header {
  // background: #FFFFFF;
  background: #222222;
  // background: linear-gradient(0deg, #333 0%, #222222 100%);
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.64);
  width: 100%;
  height: 80px;
  //绘制矩形
  // clip-path: polygon(0 0, 100% 0, 100% 70px, 0 100%);

  .web {
    height: 80px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .WebMain {
      // margin: 0 auto;
      height: 80px;
      top: 0;
      z-index: 2;
      position: absolute;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .left {
        img {
          height: 30px;
        }
      }

      .right {
        ul {
          margin: 0;
          padding: 0;
          list-style: none;
          color: white;

          li {
            display: inline-block;
            // width: 128px;
            // height: 50px;
            text-align: center;
            // padding: 11px 0;
            margin-right: 47px;
            // font-weight: 600;
            color: #f5f5f5;
            line-height: 25px;
            cursor: pointer;

            &:last-child {
              margin-right: 0px;
            }
          }

          .active {
            // background: #26E3E4;
            // border-radius: 5px;
            color: var(--custom-color);
            // color: var(--btn-color);
          }
          .lang {
        text-align: center;
        width: 115px;
        height: 36px;
        line-height: 36px;
        background: var(--custom-color);
        box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.35);
        border-radius: 5px;
        color: white;
      }
        }
      }
      
    }
  }

  .MinWeb {
    display: none;
    padding: 0 20px;
    height: 60px;

    .left,
    .center,
    .right {
      // width: 130px;
      // text-align: center;
    }

    .left {
      img {
        height: 20px;
        // width: 78px;
      }
    }

    .center {
      text-align: center;
      // li{
      //   margin-right: 77px;
      // }

      span {
        // font-size: 22px;
        font-weight: 600;
        color: var(--custom-color);
      }
    }

    .right {
      // text-align: right;
      display: flex;
      justify-content: flex-end;
      align-items: center;

      i {
        font-size: 30px;
        color: var(--custom-color);
      }

      .language {
        margin-right: 15px;

        img {
          height: 28px;
        }
      }
    }
  }
}

@media screen and (min-width: 800px) and (max-width: 1180px) {
  .header .web .WebMain .right ul li {
    margin-right: 20px;
  }
}

@media screen and (max-width: 850px) {
  .header {
    height: auto;
    .web {
      display: none;
    }

    .MinWeb {
      display: block;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>