<template>
  <div class="footer">
    <!-- 底部 -->
    <div class="WebMain">
      <div class="left" v-for="item in footerInfo" :key="item.id">
       <p class="title p14">
        {{ item.name }}
       </p>
        <p class="p14" v-for="ele in item.list" :key="ele.id">{{ ele.text }}</p>
        </div>
      <div class="right">
        
        <div class="img">
          <img src="../../assets/img/footer/logo.png" alt="" />
          <span></span>
          <img src="../../assets/img/footer/code.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      footerInfo: [
        {
          id: 1,
          // name: "联系信息",
          list: [
            { id: 1, text: "英国客服：+44 20 3192 2555" },
            { id: 2, text: "邮箱：cs@maxiveinvest.com" },
            {
              id: 3,
              text: "香港地址：Unit 5,27/F RICHMOND COMMBLDG 109 ARGYLE ST MONGKOK KLN",
            },
          ],
        },
        {
          id: 2,
          // name: "协议下载",
          list: [
            { id: 1, text: "FCA 监管号：783200" },
            { id: 2, text: "FSP 监管号：FSP612509" },
            { id: 3, text: "FSC 监管号：GB19025016" },
        ],
        }
      ],
      activeId: 1,
      activeInfo: [],
    };
  },
  methods: {
    checkActive(val) {
      this.activeId = val;
      this.footerInfo.forEach((item) => {
        if (item.id === val) {
          this.activeInfo = item.list;
        }
      });
    },
  },
  created() {
    this.checkActive(1);
  },
};
</script>

<style lang="scss" scoped>
.footer {
  background: #222;
  color: #999999;
  font-size: 14px;
  .WebMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 20px 0px;

    .left {
      p{
        margin-bottom: 12px;
        line-height: 14px;
        color: #BFBFBF;
        &:last-child{
          margin-bottom: 0px;
        }
      }
      .title{
        color: #FFFFFF;
        margin-bottom: 14px;
      }
     
    
    }

    .right {
      display: flex;
      flex-wrap: wrap;
     
      .img {
        // margin-left: 140px;
        display: flex;
        align-items: center;
        span {
          display: inline-block;
          width: 1px;
          height: 30px;
          background: rgba(255, 255, 255, 0.3);
          margin: 0 25px;
        }
        img {
          &:first-child {
            height: 30px;
          }
          &:last-child {
            height: 68px;
            width: 68px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1180px) {
  .footer .WebMain .right {
    margin: 20px 0;
  }
}
@media screen and (max-width: 750px) {
  .footer .WebMain {
    .left{
      margin-top: 20px;
     p{
      line-height: 14px;
     }
    }
    .right {
    margin: 20px 0;
    .img {
      margin-left: 0px;
      margin-top: 10px;
    }
  }
  }
}
</style>