import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
// import 'wow.js/css/libs/animate.css'
import './utils/rem.js'

// import wow from 'wow.js'// 动态效果样式文件
// import 'lib-flexible/flexible.js'
import 'amfe-flexible';
import Meta from 'vue-meta';
router.beforeEach((to, from, next) => {
  if (to.meta.metaInfo){
    store.commit("CHANGE_META_INFO", to.meta.metaInfo)
  }
  next()
});

Vue.config.productionTip = false
// Vue.prototype.$wow = wow
Vue.use(Meta)
new Vue({
  router,
  store,
  metaInfo(){
    return {
        title: this.$store.state.metaInfo.title,
        meta: [
            {
                name: "keywords",
                content: this.$store.state.metaInfo.keywords
            }, {
                name: "description",
                content: this.$store.state.metaInfo.description
            }
        ]
    }
  },
  render: h => h(App)
}).$mount('#app')
