<template>
  <div class="index">
    <div class="indexHead">
      <Header></Header>
    </div>
    <div>
      <!-- <Banner></Banner> -->
    </div>
    <div class="indexMain">
      <router-view></router-view>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from '@/components/web/Header.vue';
import Footer from '@/components/web/Footer.vue';
import Banner from '@/components/web/Banner.vue';
import Connection from '@/components/web/Connection.vue';
export default {
  components: { Header, Footer, Banner, Connection },
  created() {
    let userAgentInfo = navigator.userAgent;
    let mobileAgents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];

    let mobile_flag = false;

    //根据userAgent判断是否是手机
    for (let v = 0; v < mobileAgents.length; v++) {
      if (userAgentInfo.indexOf(mobileAgents[v]) > 0) {
        mobile_flag = true;
        break;
      }
    }
    this.$store.commit('changeIsPhone', mobile_flag)
    // console.log(mobile_flag);

  }
}
</script>

<style lang="scss">
.index {
  position: relative;
  width: calc(100%);
  height: auto;
  // background: rgba($color: #3FA3FF, $alpha: 0.02);
  .connection{
    position: fixed;
    bottom: 20rem ;
    // top: 0;
    right: 0;
    z-index: 3;
  }
  

  .indexHead {
    // background: #060936;
    // opacity: 0.4;
    width: 100%;
    // right: 0px;
    position: sticky;
    // position: absolute;
    top: 0px;
    z-index: 10;
  }

  .WebMain {
    p {
      margin: 0;
      padding: 0;
    }
    .detailTitle{
      color: var(--title-color);
      width: 100%;
      border-bottom: 2px dashed  #eee;
      padding-bottom: 20px;
      margin-bottom: 20px;
      padding-top: 30px;
    }

    // .DivTitle {
    //   font-size: 32px;
    //   font-weight: 600;
    //   color: #222222;
    //   line-height: 42px;
    //   padding-top: 60px;
    //   padding-bottom: 40px;
    //   text-align: center;
    // }

    // .DivContent {
    //   display: flex;
    //   flex-wrap: wrap;
    //   justify-content: center;

    //   .item {
    //     .title {
    //       color: #222222;
    //     }

    //     .content {
    //       color: #666666;
    //     }
    //   }
    // }
  }

}
@media screen and (max-width:500px){
  .index{
    .connection{
    bottom: 24rem ;
  }
    .WebMain{
      .DivTitle{
        font-size: 24px;
      }
    }
     
  }
}
</style>