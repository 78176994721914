<template>
    <div class="line" id="line">
        <!-- 在线客服 -->
        <div class="lineBox" v-if="$store.state.connectShow">
            <div class="close" @click="closeLine">
                <i class="el-icon-error"></i>
            </div>
            <iframe data-v-4d5550a6="" id="chiframe"
                src="https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97d4240092224dcfef1ab5e564ab2451053021d888f7ad1cbfdce2fb1a904e2f488eeb8d2407ece0ff2339b5d4986b130d"></iframe>
        </div>
        <div class="item" @click="openLine">
            <img src="../../assets/img/connection/zaixiankefu@2x.png" alt="">
            <p class="p14">在线客服</p>


        </div>
        <div class="item" @click="toTop">
            <img src="../../assets/img/connection/huidaodingbu-2@2x.png" alt="" />
            <p class="p14">回到顶部</p>
        </div>
        <!-- <div class="left">
            <iframe data-v-4d5550a6="" id="chiframe"
                src="https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97db7300c32612c8ee18b5b73647a6f2b5f7282262d9cbee466211a9eacaca0a423df614678a8584967b61c4ebd9fbb2e9"></iframe>
        </div>
        <div class="right">
            
        </div> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            show: true
        }
    },
    methods: {
        openLine() {
            this.$store.commit('changeConnectShow');
            // console.log(this.show)
            // this.show = !this.show
        },
        closeLine() {

            this.$store.commit('changeConnectShow');
        },
        toTop() {
            document.documentElement.scrollTop = 0;
            // window.pageYOffset = scroll_top;
            document.body.scrollTop = 0;
            // document.documentElement.scrollTop = 0;
        }
    }
}
</script>

<style lang="scss" scoped>
.line {

    // display: flex;
    // .left{
    //     height: 100%;
    // }
    // z-index: 10;
    position: relative;

    .lineBox {
        position: absolute;
        top: 0px;
        left: -401px;
        width: 400px;
        height: 500px;
        z-index: 2;
        // height: 30rem;
        // width: 21rem;

        // display: none;
        #chiframe {
            border-radius: 5px;
            height: 100%;
            width: 100%;
            border: 0px solid #BBB;
            box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.14);

            .chat-hd .title {
                color: var(--custom-color);
            }

            ::v-deep .chat-toolbar .chat-btn {
                background-color: #0177C4 !important;
            }

            .chat-hd .title {
                color: var(--custom-color);
            }
        }



        .close {
            position: absolute;
            z-index: 2;
            // background: #BBB;
            top: 12px;
            right: 45px;

            i {
                color: #d9d9d9;
                font-size: 30px;
            }
        }
    }

    .item {
        width: 96px;
        height: 96px;
        background: #0177C4;
        border: 1px solid #FFFFFF;
        color: white;

        text-align: center;
        // padding-top: 20px;
        // padding: 20px 30px;
        // box-sizing: border-box;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-content: center;
        &:hover{
            p{
                display: none;
            }
            img{
                transition: all .3s;
                  transform: scale(1.2);
            }
        }


        p {
            width: 100%;

        }

        img {
            height: 37px;
            width: 37px;
        }

        &:last-child {
            img {
                height: 31px;
                width: 31px;
            }
        }


    }
}

@media screen and (max-width:950px) {
    .line {
        .item {
            height: 70px;
            width: 70px;
            font-size: 12px;

            img {
                height: 20px;
                width: 20px;

            }

            &:last-child {
                img {
                    height: 18px;
                    width: 18px;
                }
            }
        }

        .lineBox {
            // top: 0px;
            width: 320px !important;
            left: -320px !important;

            .close {
                right: 5px !important;
            }
        }
    }
}</style>