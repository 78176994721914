<template>
  <div class="Banner">
    <!-- banner区域 -->

    <el-carousel  :interval="10000">
      <el-carousel-item v-for="item in imgList" :key="item.id">
        <div class="item">
          <img :src="require(`../../assets/img/banner/${item.path}`)" alt="" />
          <!-- <img v-if="!$store.state.isPhone" :src="require(`../../assets/img/banner/${item.path}`)" alt=""> -->
          <!-- <img v-else :src="require(`../../assets/img/banner/${item.path2}`)" alt=""> -->
          <div class="text wow fadeInLeft">
            <div class="WebMain">
              <p class="title p30">{{ item.title }}</p>
              <img
                :src="require(`../../assets/img/banner/${item.icon}`)"
                alt=""
              />
            </div>
          </div>
        </div>
      </el-carousel-item>
    </el-carousel>
    <!-- 三角形蒙板 -->
    <!-- <div class="box"></div> -->
  </div>
</template>

<script>
import WOW from "wow.js";
import { attachmentListAPI } from "@/api/attachment/attachment";
export default {
  data() {
    return {
      imgList: [
        {
          id: 1,
          path: "banner2.png",
          path2: "phone (1).png",
          title: "九年专注外汇行业，一站式经纪商解决方案",
          icon: "icon (2).png",
        },
        {
          id: 2,
          path: "banner.png",
          path2: "phone (1).png",
          title: "九年专注外汇行业，一站式经纪商解决方案",
          icon: "icon.png",
        },
        {
          id: 3,
          path: "banner3.png",
          path2: "phone (1).png",
          title: "九年专注外汇行业，一站式经纪商解决方案",
          icon: "icon.png",
        },
      ],
    };
  },
  methods: {
    toDetail(val) {
      this.$router.push(`/detail/${val}`);
    },
  },
  created() {},
  mounted() {
    var wow = new WOW({
      boxClass: "wow",
      // offset: 10,
      mobile: true,
      live: true,
    });
    wow.init();
  },
};
</script>

<style >
 .el-carousel__container{
    height: 743px !important;
  }
  @media screen and (max-width: 750px) {
    .el-carousel__container{
    height: 350px !important;
  }
  }
</style>
<style lang="scss" scoped>

.Banner {
  // height: 511px;
  box-sizing: border-box;
  position: relative;
  // height: 743px;
  width: 100%;
  // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 79%);
  img {
    width: 100%;
    height: 100%;
  }
 

  .item {
    position: relative;
    height: 100%;

    .text {
      position: absolute;
      top: 0;
      z-index: 2;
      width: 100%;
      // height: 500px;

      .WebMain {
        // height: 100%;
        margin: auto;
        // text-align: center;
        padding-top: 80px;
        text-align: right;

        .title {
          // margin-bottom: 20px;
          // font-weight: 600;
          line-height: 58px;
          text-align: right;
        }
        img {
          height: 60px;
          width: auto;
        }
      }

      p {
        color: white;
      }
    }
  }

  .box {
    position: absolute;
    bottom: 0;
    z-index: 3;
    height: 120px;
    width: 100%;
    background: #ffffff;
    //绘制矩形
    // clip-path: polygon(0 0, 100% 100%, 100% 100%, 0 100%);
  }
}
@media screen and (max-width: 750px) {
  .Banner {
    // clip-path: polygon(0 0, 100% 0, 100% 100%, 0 90%);
    .item .text .WebMain {
      padding-top: 100px;
    }
  }
}
</style>